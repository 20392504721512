<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  activeSubview: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["switch-subview"]);

function getClassByIndex(index, totalItems) {
  if (index === 0) {
    return "left-menu";
  } else if (index === totalItems - 1) {
    return "right-menu";
  } else {
    return "center-menu";
  }
}

function switchSubview(item) {
  emit("switch-subview", item.subview);
}
</script>

<template>
  <ion-toolbar>
    <div class="campaign-menu">
      <a
        v-for="(item, index) in items"
        :key="index"
        @click="switchSubview(item)"
        class="campaign-menu__button"
        :class="[
          getClassByIndex(index, items.length),
          { active: item.subview === activeSubview },
        ]"
      >
        <ion-icon :src="item.icon" />
      </a>
    </div>
  </ion-toolbar>
</template>

<style scoped>
.campaign-menu__button.active {
  background-color: var(--ion-color-secondary);
  border-radius: var(--ion-border-radius);
}
</style>
