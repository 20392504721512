<template>
  <ion-card>
    <section class="section">
      <!-- Sezione header cliccabile -->
      <div class="section-header" @click="toggleSection">
        <div class="section-title-container">
          <slot name="icon"></slot>
          <h3 class="section-title">{{ sectionTitle }}</h3>
        </div>

        <div
          class="flex flex-column justify-content-end align-items-end text-align-end"
          v-if="dataScadenza"
        >
          <small>Scadenza: </small
          ><small>{{
            new Date(dataScadenza).toLocaleDateString("it", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          }}</small>
        </div>
      </div>

      <!-- Barra di avanzamento con il numero percentuale sulla stessa riga -->
      <div class="progress-bar-wrapper">
        <common-progress-bar
          class="progress-bar-container"
          :value="0.3"
        ></common-progress-bar>
        <span class="progress-text">{{ sectionPerc }}</span>
      </div>

      <!-- Mostra il contenuto solo se isCollapsed è false -->
      <div v-if="!isCollapsed" class="section-content">
        <h4 class="section-items-name">{{ sectionItemsName }}</h4>
        <slot></slot>
      </div>
    </section>
  </ion-card>
</template>

<script>
import { IonCard } from "@ionic/vue";
import CommonProgressBar from "@/components/CommonProgressBar.vue";
export default {
  name: "SectionComponent",
  components: {
    IonCard,
    CommonProgressBar,
  },
  props: {
    sectionTitle: String,
    sectionItemsName: String,
    sectionPerc: {
      type: String,
      default: "100%",
    },
    dataScadenza: {
      type: String,
    },
  },

  data() {
    return {
      isCollapsed: true,
    };
  },
  methods: {
    toggleSection() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.section {
  background-color: var(--ion-color-primary);
  border-radius: 16px;
  padding: 20px 15px;
  transition: all 0.3s ease;
  color: var(--text-color-light);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  cursor: pointer; /* Indica che è cliccabile */
}

.section-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  margin-bottom: 12px;
}

.section-icon {
  width: 24px;
  height: 24px;
}

.section-content {
  margin-top: 12px;
  transition: max-height 0.3s ease;
}

.section-title {
  color: var(--text-color-light);
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.section-items-name {
  color: var(--text-color-light);
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-align: start;
}

.progress-bar-wrapper {
  display: grid;
  grid-template-columns: 80% 20%; /* Imposta le colonne */
  align-items: center; /* Allinea gli elementi verticalmente al centro */
}

.progress-bar-container {
  flex-grow: 1; /* Permette alla barra di avanzamento di espandersi */
  margin-right: 10px; /* Spazio tra la barra e il testo percentuale */
  background-color: var(
    --ion-color-secondary
  ); /* Colore di sfondo della barra */
  height: 5px; /* Altezza fissa */
  border-radius: 5px;
}

.progress-text {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 900;
  color: var(--text-color-light);
  margin-left: auto;
}
</style>
