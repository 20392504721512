<template>
  <div class="badges-card">
    <!-- <div class="collapsed-view" @click="toggleState"> -->
    <div class="collapsed-view">
      <p class="font-bold m-0">Badges</p>
      <p class="m-0">
        <span class="font-bold">{{ userBadges.length }}</span> out of
        {{ userBadgesNotCompleted.length }}
      </p>
      <!-- <ion-icon :icon="collapsed ? chevronDown : chevronUp"></ion-icon> -->
    </div>
    <div v-if="!collapsed" class="expanded-view">
      <div v-if="userBadges.length > 0" class="badges-section">
        <div class="badges-grid">
          <div v-for="badge in userBadges" :key="badge.id" class="badge">
            <img
              :src="badge.url_image"
              alt="badge image"
              class="badge-image completed"
            />
            <div class="progress-bar">
              <common-progress-bar
                :value="badge.perc_completamento"
              ></common-progress-bar>
            </div>
          </div>
        </div>
      </div>

      <div v-if="userBadgesNotCompleted.length > 0" class="badges-section">
        <div class="badges-grid">
          <div
            v-for="badge in userBadgesNotCompleted"
            :key="badge.id"
            class="badge"
          >
            <img
              :src="badge.url_image"
              alt="badge image"
              class="badge-image not-completed"
            />
            <div class="progress-bar">
              <common-progress-bar
                :value="badge.perc_completamento"
              ></common-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IonIcon } from "@ionic/vue";
import { chevronUp, chevronDown } from "ionicons/icons";
import CommonProgressBar from "@/components/CommonProgressBar.vue";
export default {
  name: "BadgesCard",
  components: { IonIcon, CommonProgressBar },
  props: {
    userBadges: Array,
    userBadgesNotCompleted: Array,
  },
  created() {
    let perdono =
      "si fa quello che si può, ma poi quando non si può più che si fa?";
  },
  data() {
    return {
      chevronUp,
      chevronDown,
      collapsed: false,
    };
  },
  methods: {
    toggleState() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style scoped>
.badges-card {
  width: 100%;
  background-color: var(--ion-color-secondary);
  border-radius: 8px;
  padding: 16px;
  color: var(--text-color-dark);
}

.collapsed-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.expanded-view {
  display: flex;
  flex-direction: column;
}

.badges-section {
  padding: 16px 0;
}

.badges-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.badge {
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.badge-image {
  width: 90%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}

.completed {
  filter: none;
}

.not-completed {
  filter: grayscale(100%);
}

.progress-bar {
  background-color: var(--text-color-light);
  height: 3px;
  width: 100%;
  border-radius: 4px;
  margin-top: 8px;
  position: relative;
}

.progress {
  background-color: var(--ion-color-primary);
  height: 100%;
  border-radius: 4px;
}

ion-icon {
  font-size: 24px;
  cursor: pointer;
}
</style>
